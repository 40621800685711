import cookieParser from 'cookie';
import jwtDecode from 'jwt-decode';
import { NextPage, NextPageContext } from 'next';
import Router from 'next/router';
import React from 'react';
import { AuthenticationMiddlewareOptions, Role } from '../../backend/middleware/auth';
import getCookie from '../get-cookie';
import { getRequestJwt } from './get-request-jwt';
import { AuthenticationTokenPayload } from '../../misc-types/jwt-payload';

/**
 * Isomorphically gets the user's token cookie from within a getInitialProps function.
 *
 * @param ctx The getInitialProps page context
 */
export function getToken(ctx: Pick<NextPageContext, 'req'>): string | undefined {
  if (typeof window !== 'undefined') {
    return getCookie('token');
  } else {
    return cookieParser.parse(ctx.req?.headers?.cookie ?? '').token;
  }
}

/**
 * Gets the JWT from a Next page context but DOES NOT VERIFY it.
 */
export function getJwt(ctx: NextPageContext): AuthenticationTokenPayload | undefined {
  const token = getToken(ctx);
  return token ? jwtDecode(token) as AuthenticationTokenPayload : undefined;
}

/**
 * Requires authentication on a next.js page
 */
const withAuth = <TRole extends Role, P, IP = P>(
  { role: requiredRole }: AuthenticationMiddlewareOptions<TRole>,
  Page: NextPage<P, IP>
): NextPage<P, IP> => {
  const getInitialProps = async (ctx: NextPageContext) => {
    async function mayAccessPage() {
      if (typeof window !== 'undefined') {
        try {
          if (requiredRole == null) return true;
          const rawToken = getCookie('token');
          if (rawToken == null) return false;
          const jwtPayload = jwtDecode(rawToken) as AuthenticationTokenPayload;
          return jwtPayload.role === requiredRole;
        } catch {
          return false;
        }
      } else {
        const jwtPayload = await getRequestJwt(ctx.req!);
        return requiredRole == null || jwtPayload?.role === requiredRole;
      }
    }
    if (await mayAccessPage()) {
      return Page.getInitialProps?.(ctx) ?? {};
    } else {
      const attemptedPath = ctx.pathname;
      const newPath = `/login/${requiredRole}?continue=${encodeURIComponent(attemptedPath)}`;
      if (typeof window !== 'undefined') {
        Router.replace(newPath);
      } else {
        ctx.res?.writeHead?.(302, { Location: newPath, 'Content-Type': 'text/html; charset=utf-8' });
        ctx.res?.end?.();
      }
      // {} does not match IP (initial props) type but that shouldn't matter if it never renders
      return {} as any;
    }
  };
  const newPage: typeof Page = ({ ...props }) => React.createElement(Page, props); // = <Page {...props} />;
  newPage.displayName = `pageWithAuth(${Page.name})`;
  newPage.getInitialProps = getInitialProps;
  return newPage;
};

export default withAuth;
