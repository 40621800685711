import { NextPageContext } from 'next';
import { ReactNode, useCallback, useState } from 'react';
import { Method } from '../backend/middleware/with-all-middleware';
import { HOST } from '../global';
import { getToken } from './auth/page-auth';
import getCookie from './get-cookie';

export class HttpError extends Error {
  status: number;

  constructor(status: number, message?: string) {
    super(message);
    this.status = status;
  }
}

function jsonToQueryString(json?: Record<string, string>) {
  return json == null
    ? ''
    : `?${Object.keys(json)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`)
        .join('&')}`;
}

type RequestData<TMethod extends Method> = Exclude<
  TMethod extends 'get' ? Record<string, string> : Record<string | number, any>,
  { token: any }
>;

export interface UseRequestState<TData> {
  data: TData | undefined;
  fetching: boolean;
  error: Error | undefined;
}

/**
 * Makes a request to the API. Works server-side and client-side.
 *
 * @param path The path to send the request to, excluding `/api/`. Example: 'members/18'
 * @param method The HTTP method to use
 * @param data Request body, or parameters if the request method is GET
 * @param otherParams Other fetch request parameters
 */
export default function request<TMethod extends Method, TResult>(
  path: string,
  method: TMethod,
  data?: RequestData<TMethod>,
  otherParams: RequestInit = {}
): Promise<TResult> {
  const token = data?.token ? data.token : typeof window !== 'undefined' ? getCookie('token') : undefined;
  // allow data to override token if necessary
  const dataWithToken = token ? { token, ...data } : data;
  return fetch(`${HOST}/api/${path}${method === 'get' ? jsonToQueryString(dataWithToken) : ''}`, {
    credentials: 'same-origin',
    ...(otherParams ?? {}),
    method: method.toUpperCase(),
    body: method === 'get' ? undefined : JSON.stringify(dataWithToken)
  }).then(async (resp): Promise<TResult> => {
    const response = await resp.text();
    const MAX_ERROR_MESSAGE_LENGTH = 120;
    const truncatedText =
      response.length > MAX_ERROR_MESSAGE_LENGTH ? `${response.slice(0, MAX_ERROR_MESSAGE_LENGTH)}...` : response;
    if (!resp.ok) throw new HttpError(resp.status, truncatedText);
    return response ? JSON.parse(response) : null;
  });
}

/**
 * Makes a GET request from a getInitialProps function. Can be used client-side or server-side.
 *
 * @param path The api request path
 * @param ctx The getInitialProps context
 * @param data The request parameters to be sent, encoded within the url
 * @param otherParams Other fetch request parameters
 */
export function makeInitialPropsGetRequest<TResult>(
  path: string,
  ctx: Pick<NextPageContext, 'req'>,
  data?: RequestData<'get'>,
  otherParams: RequestInit = {}
): Promise<TResult> {
  const headers: { Cookie: string } | {} = ctx.req?.headers?.cookie ? { Cookie: ctx.req.headers.cookie } : {};
  const token = getToken(ctx);
  return request(path, 'get', token ? { token, ...data } : data, {
    ...otherParams,
    headers: { ...otherParams.headers, ...headers }
  });
}

export function useRequest<TMethod extends Method, TResult>(path: string, method: TMethod) {
  const [state, setState] = useState<UseRequestState<TResult>>({
    data: undefined,
    fetching: false,
    error: undefined
  });
  const execute = useCallback(
    async (requestData?: RequestData<TMethod>) => {
      setState(s => ({ ...s, fetching: true }));
      try {
        const result = await request<TMethod, TResult>(path, method, requestData);
        setState(s => ({ ...s, data: result, fetching: false, error: undefined }));
        return result;
      } catch (error) {
        setState(s => ({ ...s, data: undefined, fetching: false, error: error as Error }));
        throw error;
      }
    },
    [path, method]
  );
  return [state, execute] as [typeof state, typeof execute];
}

export function switchRequestState<T>(
  requestState: UseRequestState<T>,
  success: (data: T) => ReactNode,
  error: (e: Error) => ReactNode,
  loading: ReactNode
): ReactNode {
  return requestState.fetching
    ? loading
    : requestState.error
    ? error(requestState.error)
    : requestState.data !== undefined
    ? success(requestState.data)
    : null;
}
