import classNames from 'classnames';
import Link from 'next/link';
import { useState, useContext } from 'react';
import { Menu } from 'react-feather';
import { UserContext } from './user-provider';
import { useFirstRender } from '../utils/hooks';
import { Role } from '../backend/middleware/auth';
import { BusinessJwt } from '../misc-types/jwt-payload';

const LogoutButton: React.FC = () => {
  const userContext = useContext(UserContext);
  return (
    <button
      className='tertiary nav-link'
      onClick={() => 'logout' in userContext && userContext.logout()}
      style={{ fontWeight: 500 }}
    >
      Log out
    </button>
  );
};

const CUSTOMIZED_LINKS: Record<Role | 'unknown', JSX.Element | React.FC> = {
  admin: (
    <>
      <Link href='/manage'>
        <a className='nav-link mr-4 md:ml-auto'>Home</a>
      </Link>
      <LogoutButton />
    </>
  ),
  member: (
    <>
      <Link href='/home/member/settings'>
        <a className='nav-link mr-4 md:ml-auto'>Settings</a>
      </Link>
      <LogoutButton />
    </>
  ),
  business: function BusinessLinks() {
    const { jwt } = useContext(UserContext);
    return (
      <>
        <Link href='/businesses/[id]' as={`/businesses/${(jwt as BusinessJwt).sub}`}>
          <a className='nav-link mr-4 md:ml-auto'>Profile</a>
        </Link>
        <Link href='/home/business'>
          <a className='nav-link mr-4'>Home</a>
        </Link>
        <LogoutButton />
      </>
    );
  },
  unknown: (
    <>
      <Link href='/login/member'>
        <a className='nav-link mr-4 md:ml-auto'>Member login</a>
      </Link>
      <Link href='/login/business'>
        <a className='nav-link'>Business login</a>
      </Link>
    </>
  )
};

const TopNavigationBar: React.FC = () => {
  const { jwt } = useContext(UserContext);
  // can't customize links in SSR, and useFirstRender only runs client side
  // avoid using "typeof window" because warnings show up in console about
  // client-server mismatch
  const [customizeLinks, setCustomizeLinks] = useState(false);
  const role = jwt?.role && customizeLinks ? jwt.role : 'unknown';
  useFirstRender(() => setCustomizeLinks(true));
  const [expanded, setExpanded] = useState(false);
  const Links: React.FC =
    typeof CUSTOMIZED_LINKS[role] === 'function'
      ? (CUSTOMIZED_LINKS[role] as React.FC)
      : () => CUSTOMIZED_LINKS[role] as JSX.Element;
  return (
    <>
      <nav className='relative flex items-center md:items-baseline justify-between flex-wrap bg-gray-900 text-gray-300 p-6 leading-none select-none flex-shrink-0'>
        <Link href='/'>
          <a className='mr-4 text-gray-100'>
            <img
              src='/images/logo-34.png'
              srcSet='/images/logo-34.png 1x, /images/logo-68.png 2x, /images/logo-102.png 3x'
              alt=''
              width='34'
              className='inline align-text-top -my-2 mr-2'
            />
            FITSOCIETY.COM
          </a>
        </Link>
        <button
          className='flex md:hidden focus:text-gray-500 touched:text-gray-500'
          onClick={() => setExpanded(e => !e)}
          aria-label='Toggle menu'
        >
          <Menu />
        </button>
        <div
          className={classNames(
            'text-sm font-medium w-full block flex-grow md:flex md:items-center md:w-auto',
            !expanded && 'hidden md:visible'
          )}
        >
          <Link href='/#categories'>
            <a className='nav-link mr-4'>Categories</a>
          </Link>
          <Link href='/shop'>
            <a className='nav-link mr-4'>Shop</a>
          </Link>
          <Link href='/about'>
            <a className='nav-link mr-4'>About</a>
          </Link>
          <Link href='/blog'>
            <a className='nav-link'>Blog</a>
          </Link>
          <Links />
        </div>
        <img
          src='/images/logo-102.png'
          alt=''
          className='big-center-logo hidden lg:block z-20'
          width='102'
          height='102'
        />
      </nav>
      <style jsx>{`
        :global(.nav-link) {
          @apply block mt-4;
        }
        @screen md {
          :global(.nav-link) {
            @apply inline-block mt-0;
          }
        }

        .big-center-logo {
          position: absolute;
          left: 50%;
          top: 0.2rem;
          transform: translateX(-50%);
          filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.4));
        }
      `}</style>
    </>
  );
};

export default TopNavigationBar;
