import cookie from 'cookie';
import { IncomingMessage } from 'http';
import { verify } from '../../backend/jwt';
import { AuthenticationTokenPayload } from '../../misc-types/jwt-payload';

/**
 * Gets the JWT cookie for a request and parses and verifies it. Returns undefined if the user is not logged in or
 * their token is invalid.
 */
export async function getRequestJwt(req: IncomingMessage): Promise<AuthenticationTokenPayload | undefined> {
  const { token } = cookie.parse(req.headers.cookie ?? '', {});
  if (token == null) {
    return undefined;
  }
  return verify(token, 'auth').catch(() => undefined);
}
